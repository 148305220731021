import * as Getter from './getter-types'

export default {
  [Getter.ANY_OPEN]: (state) => {
    return state.edit || state.delete || state.leave || state.removeNode || state.copy
  },

  [Getter.GET_CURRENT_WORKSPACE]: (state) => state.currentWorkspace,

  [Getter.GET_WORKSPACE_EDIT]: (state) => ({
    show: state.edit,
    workspace: state.currentWorkspace
  }),

  [Getter.IS_DELETE]: (state) => state.delete,

  [Getter.IS_LEAVE]: (state) => state.leave,

  [Getter.IS_COPY]: (state) => state.copy
}
